<template>
    <v-app id="inspire">
    <app-bar-front-page></app-bar-front-page>
    <v-main>
        <v-container fluid fill-height>
            <v-layout align-center justify-center>
            <v-flex xs12 sm8 md8 lg4>
                <v-card class="elevation-5 pa-3">
                <v-card-text>
                    <div class="layout column align-center">
                    <img :src="getLogo" alt="Dovolilnice | Občina Bled - Ponastavitev gesla" height="auto">
                    <br><br>
                    <div>
                        <p class="headline font-weight-medium text-center">Dovolilnice | Občina Bled</p>
                    </div>
                    <div>
                        <p class="title font-weight-medium text-center">Ponastavitev gesla</p>
                    </div>
                    <v-layout align-center justify-center="">
                        <v-flex xs12 sm12 md10 lg10>
                            <div class="">
                                <p style="text-align:center;" class="font-weight-medium">Za ponastavitev gesla vnesite svoj elektronski naslov. Na vaš elektronski naslov vam bodo poslana nadaljna navodila za ponastavitev vašega gesla.</p>
                            </div>
                        </v-flex>
                    </v-layout>
                    </div>                
                    <br><br>
                    <v-form v-model="valid" ref="resetForm" >
                    <v-text-field append-icon="mdi-email" @keyup.esc="clearEmailInput" @keyup.enter="sendRequest" name="email" label="Vaš elektronski naslov" type="email" v-model="email" color="tertiary" :rules="[rules.required, rules.email]" clearable=""></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                    <v-spacer></v-spacer>
                    <v-btn  class="white--text" dark color="secondary" @click="redirectToLogin">Prijava</v-btn>
                    <v-btn  class="white--text" dark color="secondary" @click.native="sendRequest" :loading="loading">Ponastavi geslo</v-btn>
                </v-card-actions>
                <v-card-text v-else>
                    <v-btn block outlined style="margin-bottom:10px;"  class="white--text" dark color="secondary" @click="redirectToLogin">Prijava</v-btn>
                    <v-btn block outlined style="margin-bottom:10px;"  class="white--text" dark color="secondary" @click.native="sendRequest" :loading="loading">Ponastavi geslo</v-btn>
                </v-card-text>
                </v-card>
            </v-flex>
            </v-layout>
        </v-container>

        <v-snackbar
            v-model="$store.getters.snackbar.display"
            :bottom="$store.getters.snackbar.y === 'bottom'"
            :left="$store.getters.snackbar.x === 'left'"
            :multi-line="$store.getters.snackbar.mode === 'multi-line'"
            :right="$store.getters.snackbar.x === 'right'"
            :timeout="$store.getters.snackbar.timeout"
            :top="$store.getters.snackbar.y === 'top'"
            :vertical="$store.getters.snackbar.mode === 'vertical'"
            >
            {{ $store.getters.snackbar.text }}
            <v-btn
                color="white"
                text
                @click="snackbar"
            >
                Zapri
                </v-btn>
        </v-snackbar>
        <network-status></network-status>
    </v-main>
  </v-app>
</template>

<script>
import AppBarFrontPage from '@/components/layout/AppBarFrontPage'
import logo from '@/assets/grb_bled_135_135.png'
const NetworkStatus = () =>import('@/components/NetworkStatus')


export default {
    components: {
        AppBarFrontPage,
        NetworkStatus
    },
    
    data: () => ({
        email: null,
        loading: false,
        rules: {
            required: value => !!value || 'Polje za vnos elektronskega naslova je obvezno',
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Elektronski naslov je neveljaven'
            }
        },
        valid: false
    }),

    watch: {
        email(value) {
            if(this.email == null) {
                this.email = ''
                this.$refs.resetForm.resetValidation()
            }
        }
    },

    computed: {
        getLogo() {
            //return 'https://cloud.conforma.si/public/img/obcina-bled/grb_bled_135_135.png'
            return logo
        }
    },

    methods: {
        sendRequest() {
            this.$refs.resetForm.validate();
            //console.log(this.valid)
            if(this.valid) {
                this.$recaptcha("login").then(token => {
                    let captchaRecaptchaResponse = token;
                    this.loading = true
                    this.$store.dispatch('PASSWORD_RESET_REQUEST', { email: this.email, secret: process.env.VUE_APP_UUID, 'g-recaptcha-response': captchaRecaptchaResponse })
                    .then(response => {
                        //window.console.log(response)
                        this.email = null
                        this.loading = false
                        this.$store.commit('SET_SNACKBAR_TEXT', 'Na vaš elektronski naslov so vam bila poslana poslana navodila za ponastavitev vašega gesla.')
                        this.$store.commit('SET_SNACKBAR_DISPLAY', true) 
                    })
                    .catch(error => {
                        window.console.log(error)
                        this.loading = false
                        if(error.response.status == 403) {
                            this.$store.commit('SET_SNACKBAR_TEXT', 'Uporabnik s tem elektronskim naslovom ne obstaja.')
                        } else {
                            this.$store.commit('SET_SNACKBAR_TEXT', 'Pri ponastavitvi gesla je prišlo do napake.')
                        }

                        this.$store.commit('SET_SNACKBAR_DISPLAY', true) 
                    })
                });
            }
        },

        redirectToLogin() {
            return this.$router.push({
                name: 'login'
            })
        },

        clearEmailInput() {
            this.email = null
            this.$refs.resetForm.resetValidation()
        },

        snackbar() {
            this.$store.state.snackbar.display = false
        },
    },

    created() {

    },

    mounted() {
        //console.log(this.valid)
    },

    destroyed() {
        this.$store.commit('SET_SNACKBAR_DISPLAY', false) 
    }
}
</script>

<style scoped lang="css">


    #inspire{
        background-image: url("../../assets/bckpattern.png");
        background-repeat: repeat;
    }
</style>


